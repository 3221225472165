import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FormattedMessage } from 'react-intl';

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { makeStyles } from 'tss-react/mui';
import { closeSnackbar as closeSnackbarAction } from 'actions/snackbarActions';
import AppConstants from 'constants/appconstants';

const useStyles = makeStyles()((theme) => ({
  leftSidebar: {
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10),
      left: `calc(50% + ${AppConstants.LEFT_NAV_BAR_WIDTH / 2}px)`,
    },
  },
  rightSidebar: {
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10),
      left: `calc(50% - ${AppConstants.RIGHT_NAV_BAR_WIDTH / 2}px)`,
    },
  },
  bothSidebar: {
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10),
      left: `calc(50% - ${
        (AppConstants.RIGHT_NAV_BAR_WIDTH - AppConstants.LEFT_NAV_BAR_WIDTH) / 2
      }px)`,
    },
  },
  success: {
    backgroundColor: theme.palette.secondary.success, // green
  },
  error: {
    backgroundColor: theme.palette.secondary.main, // red
  },
  info: {
    backgroundColor: theme.palette.primary.background, // dark grey
  },
  default: {
    backgroundColor: theme.palette.primary.main, // blue
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: CheckCircleIcon,
};

export function ConnectedSnackbar({
  open,
  onClose,
  variant,
  drawerOpen,
  icon,
  ...rest
}) {
  const Icon = icon || variantIcon[variant];
  const { classes, cx } = useStyles();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      className={cx({
        [classes.leftSidebar]: drawerOpen === 'left',
        [classes.rightSidebar]: drawerOpen === 'right',
        [classes.bothSidebar]: drawerOpen === 'both',
      })}
    >
      <SnackbarContent
        data-test-id="global-snackbar"
        className={classes[variant]}
        message={(
          <span className={classes.message}>
            {!!Icon && (
              <Icon
                data-test-id={variant}
                className={cx(classes.icon, classes.iconVariant)}
              />
            )}
            {!!rest.id && <FormattedMessage {...rest} />}
          </span>
        )}
        action={[
          <IconButton
            name="close-button"
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

ConnectedSnackbar.defaultProps = {
  variant: 'default',
  drawerOpen: '',
  icon: null,
};

ConnectedSnackbar.propTypes = {
  icon: PropTypes.func,
  open: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['default', 'success', 'error', 'info']),
};

function mapStateToProps({ snackbar }) {
  return {
    open: snackbar.open,
    ...snackbar.options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: bindActionCreators(closeSnackbarAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedSnackbar);
