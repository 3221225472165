import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertWarning from '@mui/icons-material/Warning';

/**
  * @module routes/Manager/components/ErrorDialog
  */

const useStyles = makeStyles()((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: '#B71C1C',
  },
  details: {
    color: theme.palette.text.secondary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  defaultTitle: {
    marginRight: theme.spacing(3),
  },
}));

const variantIcon = {
  error: ErrorIcon,
  warning: AlertWarning,
};
/**
  * ErrorDialog stateless function component - Renders a error dialog
  * @function ErrorDialog
  * @param {ErrorDialogProps} ErrorDialogProps - The
  * [props]{@link module:routes/Manager/components/ErrorDialog~ErrorDialogProps}
  * for the ErrorDialog component.
  * @return {function} ErrorDialog - stateless ErrorDialog component
  */
export default function ErrorDialog({
  errors, onRequestClose, children, cancelLabel, errorTitle, variant,
}) {
  const { classes } = useStyles();
  const intl = useIntl();
  const {
    status,
    body: {
      id, message, values, errors: details,
    },
  } = errors;
  const Icon = variantIcon[variant || 'error'];

  return (
    <Dialog open onClose={onRequestClose}>
      <DialogTitle className={classes.title}>
        {!!Icon && <Icon className={classes.icon} />}
        <Typography component="span" className={classes.defaultTitle}>
          {errorTitle || <FormattedMessage id="errors.default_title" />}
        </Typography>
        {onRequestClose && (
          <IconButton
            name="close-icon-button"
            aria-label={intl.formatMessage({ id: 'buttons.close' })}
            className={classes.closeButton}
            onClick={onRequestClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText data-test-id="errorBody">
          <FormattedMessage
            id={`errors.${id || status}`}
            values={{
              ...values,
              /* These components are fine being "unstable" as they don't contain any state */
              /* eslint-disable react/no-unstable-nested-components */
              supportlink: (chunk) => (
                <a href="http://www.alchemysystems.com/support/" target="_blank" rel="noopener noreferrer">{chunk}</a>
              ),
              MailSupport: <a href="mailto:support@alchemysystems.com">support@alchemysystems.com</a>,
              /* eslint-enable react/no-unstable-nested-components */
            }}
            defaultMessage={message || intl.formatMessage({ id: 'errors.default_message' })}
          />
        </DialogContentText>
        {details && details.length && (
          <ul className={classes.details}>
            {details.map(({ path, error, resource }) => {
              let detail = null;
              if (path && error && resource) {
                const messageId = `errors.${resource}.${path.replace('/', '')}.${error}`;
                detail = (
                  <li key={messageId}>
                    <FormattedMessage
                      data-test-id="errorDetails"
                      id={messageId}
                      values={{
                        // These components are fine being "unstable" as they
                        // don't contain any state
                        /* eslint-disable react/no-unstable-nested-components */
                        supportlink: (chunk) => (
                          <a href="http://www.alchemysystems.com/support/" target="_blank" rel="noopener noreferrer">{chunk}</a>
                        ),
                        MailSupport: <a href="mailto:support@alchemysystems.com">support@alchemysystems.com</a>,
                        /* eslint-enable react/no-unstable-nested-components */
                      }}
                    />
                  </li>
                );
              }
              return detail;
            })}
          </ul>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button name="close-button" onClick={onRequestClose}>
          {cancelLabel || <FormattedMessage id="simple_dialog.close" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
  * @typedef ErrorDialogProps
  * @type {Object}
  * @property {Object} errors - Object (response) with the detail of the error (id, status, etc)
  * @property {function} onRequestClose - Function to call when the dialog is closed
  * @property {Object} intl - Intl object to translate the default message. Required.
  */
ErrorDialog.propTypes = {
  errors: PropTypes.shape({
    body: PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      values: PropTypes.object,
      errors: PropTypes.array,
    }).isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  cancelLabel: PropTypes.node,
  errorTitle: PropTypes.node,
  variant: PropTypes.oneOf(['error', 'warning']),
};

ErrorDialog.defaultProps = {
  onRequestClose: () => { },
  children: null,
  cancelLabel: null,
  errorTitle: null,
  variant: 'error',
};
